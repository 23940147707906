import React, {useState} from 'react';
import './App.css';
<link href='https://fonts.googleapis.com/css?family=Montserrat' rel='stylesheet'></link>
function App() {
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
  
    const handleEmailChange = (e) => {
      setEmail(e.target.value);
    };
  
    const handlePasswordChange = (e) => {
      setPassword(e.target.value);
    };

  return (
    <div className="login-container">
      <div className="left-section">
        {/* Background image */}
        <img
          src="background.png"
          alt="Background"
          className="background-image"
        />
      </div>
      <div className="right-section">
        {/* Logo */}
        <img
          src="logo.png"
          alt="Logo"
          className="logo"
        />

        <h2>Bienvenue</h2>
        {/* Login Form */}
        <div className="login-form">
          <form>
            <div className="form-group">
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Entrer votre e-mail"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={handlePasswordChange}
                placeholder="Tapez votre mot de passe"
                required
              />
            </div>
            <button type="submit">Se connecter</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default App;
