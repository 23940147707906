import React, { useState } from 'react';
import './App.css';


function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Array of pages with their names and images
  const pages = [
    { name: 'Clients', image: 'users-more-svgrepo-com 1.png'},
    { name: 'Boutique', image: 'shop-2-svgrepo-com 1.png'},
  ];

  const metrics = [
    { name: "Chiffre d'affaires généré par iGolf.One", value: '€56000'},
    { name: "Pourcentage de cartes activées", value: 75 },
    { name: 'Nombre de Clients', value: '136 Clients' },
  ];

  return (
    <div className="App">
      <header className="App-header">
        <button onClick={toggleSidebar} className="Sidebar-toggle">
          ☰
        </button>
        <img src='logo.png' alt="Logo 1" className="Header-logo1" />
        <img src='logo2.jpeg'alt="Logo 2" className="Header-logo" />
      </header>
      <div className={`Sidebar ${isSidebarOpen ? 'Sidebar-open' : ''}`}>
        {/* Render clickable elements when the sidebar is closed */}
        {!isSidebarOpen && (
          <div className="Clickable-logos">
            {pages.map((page, index) => (
              <div key={index} className="Clickable-logo">
                <img src={page.image} alt={page.name} />
              </div>
            ))}
          </div>
        )}

        {/* Render images and text when the sidebar is open */}
        {isSidebarOpen && (
          <div className="Clickable-images-and-text">
            {pages.map((page, index) => (
              <div key={index} className="Clickable-item">
                <img src={page.image} alt={page.name} />
                <span className="Clickable-item-text">{page.name}</span>
              </div>
            ))}
          </div>
        )}
      </div>
      <main className="Main-content">
        <h2 className={`Header ${isSidebarOpen ? 'mainheaderopen' : 'mainheaderclosed'}`}>
          UGolf Dashboard
        </h2>
        <div className="Bubble-area">
          <div className="Bubble">
            <span className="Metric-name">Chiffre d'affaires généré par iGolf.One</span>
            <span className="Metric-value">€0</span>
          </div>
        </div>
        <div className="Bubble-area">
          <div className="Bubble">
            <span className="Metric-name">Nombres de cartes distribuées</span>
            <span className="Metric-value">0</span>
          </div>
        </div>
        <div className="Bubble-area">
          <div className="Bubble">
            <span className="Metric-name">Nombres de cartes activitées</span>
            <span className="Metric-value">0</span>
          </div>
        </div>
        <div className="Bubble-area">
          <div className="Bubble">
            <span className="Metric-name">% cartes distribué vs cartes activé</span>
            <span className="Metric-value">0%</span>
          </div>
        </div>
        <div className="Bubble-area">
          <div className="Bubble">
            <span className="Metric-name">Nombre de comptes iGolf.One</span>
            <span className="Metric-value">0 Clients</span>
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;

